import { MessageNotice } from "../types";
import { CadminInterface } from "./types";

export default class Notice {
    constructor(private readonly cadmin: CadminInterface) {}

    public success(message: string, duration: number = 3000, title: string = "提示") {
        this.push("success", title, message, duration);
    }

    public warning(message: string, duration: number = 5000, title: string = "警告") {
        this.push("warning", title, message, duration);
    }

    public info(message: string, duration: number = 3000, title: string = "提示") {
        this.push("info", title, message, duration);
    }

    public error(message: string, duration: number = 5000, title: string = "错误提示") {
        this.push("error", title, message, duration);
    }

    private push(type: MessageNotice["type"], title: string, message?: string, duration?: number) {
        const data: MessageNotice = {
            type,
            title,
            message,
            duration,
        };
        void this.cadmin.message.send("DashboardNotice", data);
    }
}
