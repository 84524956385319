export { Request } from "@/request";
export type { Response } from "@/request";
export type { AxiosResponse } from "axios";
export * from "./env";
export * from "./types";
export * from "./load";
export * from "./page";
export { Message } from "./message";
export { createDashboardMessage } from "./dashboard";
export { MessageListenerAbstract } from "./dashboard/message";
import { cadminLoad, Cadmin } from "./feature/cadmin";
import version from "./version";

export { cadminLoad };

window.cadminLoad = cadminLoad;

console.info(window.location.href, "cadmin-client-library version:", version);

declare global {
    interface Window {
        cadmin: Cadmin;
        cadminLoad: typeof cadminLoad;
    }
}
