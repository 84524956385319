import { createMessage as _createMessage, Message } from "../message";
import { MessageListenerAbstract } from "./message";

export { Request } from "@/request";
export type { Response } from "@/request";
export type { AxiosResponse } from "axios";
export * from "../env";

export * from "../types";
export * from "../load";
export * from "../page";

export const createDashboardMessage = (element: HTMLElement, listener: MessageListenerAbstract) => {
    const message = _createMessage(element);
    message.addListener("DashboardLoading", listener.loading());
    message.addListener("DashboardNotice", listener.notice());
    message.addListener("DashboardContext", listener.context());
    message.addListener("DashboardOpen", listener.open());
    message.addListener("DashboardClose", listener.close());
    return message;
};

export { MessageListenerAbstract, Message };
