import { Request as CommonRequest, Response } from "@/request";
import { Env } from "@/env";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { CadminInterface } from "./types";

export default class Request<T = any> extends CommonRequest<T> {
    constructor(
        private readonly cadmin: CadminInterface,
        env: Env,
        action: string,
    ) {
        super(env, action);
    }

    protected getStartHandleFunc() {
        return () => {
            this.cadmin.loading("open", this._loadingType);
            super.getStartHandleFunc()?.();
        };
    }

    protected getFailHandleFunc() {
        return () => {
            this.cadmin.notice.error("对不起您请求的数据不存在或者返回异常");
            super.getFailHandleFunc()?.();
        };
    }

    protected getSuccessHandleFunc() {
        return (data: T, apiResponse: Response<T>, response: AxiosResponse<Response<T>>) => {
            if (this._tipSuccess) {
                this.cadmin.notice.success(apiResponse.message);
            }
            super.getSuccessHandleFunc()?.(data, apiResponse, response);
        };
    }

    protected getErrorHandleFunc() {
        return (data: T, apiResponse: Response<T>, response: AxiosResponse<Response<T>>) => {
            if (this._tipError) {
                this.cadmin.notice.error(apiResponse.message, 10000);
            }
            super.getErrorHandleFunc()?.(data, apiResponse, response);
        };
    }

    protected getCompleteHandleFunc() {
        return (data?: T, apiResponse?: Response<T>, response?: AxiosResponse<Response<T>>) => {
            this.cadmin.loading("close", this._loadingType);
            super.getCompleteHandleFunc()?.(data, apiResponse, response);
        };
    }

    protected getConfig(): AxiosRequestConfig {
        return {
            ...super.getConfig(),
            ...(this.cadmin.globalConfig.request ? this.cadmin.globalConfig.request : {}),
        };
    }
}
