import type { Config, Server, Token } from "@/types";

export type EnvSerializeType = {
    server: Server;
    config: Config;
    token?: Token;
};

export class Env {
    constructor(
        public readonly server: Server,
        public config: Config,
        public token?: Token,
    ) {}

    public static createUrl(url: string, action: string) {
        return url.replace(/\/+$/g, "") + "/" + action.replace(/^\/+/g, "");
    }

    public url(action: string = "") {
        if (action === "") {
            return this.server.url;
        }
        return Env.createUrl(this.server.url, action);
    }

    public header() {
        const headers: Record<string, any> = {};
        if (this.token) {
            headers["Authorization"] = "Bearer " + this.token.value;
        }
        return headers;
    }

    get dashboardCollapse() {
        return this.config.dashboardCollapse;
    }

    get developmentMenuGroup() {
        return this.config.developmentMenuGroup;
    }

    get isLogin() {
        return !!this.token;
    }

    get name() {
        return this.server.name;
    }

    public static unSerialize(data: EnvSerializeType) {
        return new Env(data.server, data.config, data.token);
    }

    public serialize(): EnvSerializeType {
        return JSON.parse(
            JSON.stringify({
                server: this.server,
                config: this.config,
                token: this.token,
            }),
        );
    }
}

export const createEnv = (server: Server, config: Config, token?: Token) => new Env(server, config, token);
