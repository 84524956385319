import { createMessage } from "@/message";
import Notice from "./notice";
import Request from "./request";
import { Load } from "../load";
import { Env, EnvSerializeType } from "../env";
import { CadminInterface, GlobalConfig } from "./types";
import { MessageLoading, MessagePage } from "@/types.ts";
import version from "@/version";

let isLoadContext: boolean = false;

export class Cadmin implements CadminInterface {
    private readonly _message;
    private readonly _notice: Notice;
    private _env?: Env;
    private _context?: Load;
    private readonly _globalConfig: GlobalConfig = {};

    constructor(globalConfig: GlobalConfig) {
        this._globalConfig = globalConfig;
        this._message = createMessage(window.parent);
        this._notice = new Notice(this);
        this.init();
    }

    public init() {
        // 刷新当前页面
        this._message.addListener("FeatureRefresh", () => window.location.reload());
        // 获取页面标题
        this._message.addListener("FeatureTitle", () => document.title);

        // 获取上下文
        this._message
            .send<{ env: EnvSerializeType; context: Load }>("DashboardContext")
            .then(data => {
                this._env = Env.unSerialize(data.env);
                this._context = data.context;
                isLoadContext = true;
            })
            .catch(e => {
                throw new Error(e);
            });
    }

    public request<T = any>(action: string): Request<T> {
        return new Request<T>(this, this.env, action);
    }

    // 获取环境信息
    get env() {
        if (!this._env) {
            throw new Error("env data not found");
        }
        return this._env;
    }

    get context() {
        if (!this._context) {
            throw new Error("context data not found");
        }
        return this._context;
    }

    // 当前用户信息
    get user() {
        return this.context.user;
    }

    // 消息提示
    get notice() {
        return this._notice;
    }

    // 打开页面
    public open(data: MessagePage) {
        void this._message.send("DashboardOpen", data);
    }

    // 关闭当前页面
    public close() {
        void this._message.send("DashboardClose");
    }

    // loading
    public loading(status: MessageLoading["status"], type: MessageLoading["type"]) {
        if (type === "none") {
            return;
        }
        const data: MessageLoading = { status, type };
        void this._message.send("DashboardLoading", data);
    }

    // 权限校验
    public check(action: string, method: string) {
        return !!this.context.request.find(request => {
            return request.action === action && request.method.toLowerCase() === method.toLowerCase();
        });
    }

    // 获取用户 by id
    public getUserById(id: number) {
        return this.context.allUser.find(user => user.id === id);
    }

    get message() {
        return this._message;
    }

    get version() {
        return version;
    }

    get globalConfig() {
        return this._globalConfig;
    }
}

export const cadminLoad = async (globalConfig?: GlobalConfig) => {
    if (window.cadmin) {
        return;
    }
    window.cadmin = new Cadmin(globalConfig || {});
    for (let i = 0; i < 20; i++) {
        if (isLoadContext) {
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 15));
    }
    throw new Error("context load failed");
};
