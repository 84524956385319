import { LoadMenu } from "@/load";
import { Page, PageOption } from "@/types";

const generateRandomString = (length: number) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

const createPage = (
    url: string,
    title: string,
    type: Page["type"],
    option?: { menuId?: number } & PageOption,
): Page => {
    if (url === "") {
        throw new Error("url 不能为空");
    }
    const searchParams = new URLSearchParams();
    if (option?.query) {
        Object.keys(option.query).forEach(key => {
            searchParams.append(key, option.query?.[key]);
        });
        if (url.includes("?")) {
            url = url + "&" + searchParams.toString();
        } else {
            url = url + "?" + searchParams.toString();
        }
    }
    return {
        key: generateRandomString(30),
        title: title,
        menuId: option?.menuId,
        url: url.toString(),
        reloadFlag: 1,
        type,
        isNew: option?.isNew || false,
    };
};

export const createPageByUrl = (url: string, title: string, type: Page["type"], option?: PageOption): Page => {
    return createPage(url, title, type, option);
};

export const createPageByMenu = (
    menu: LoadMenu,
    type: Page["type"],
    option?: { menuId?: number } & PageOption,
): Page => {
    return createPage(menu.url, menu.name, type, option);
};
