import axios, { Method, ResponseType, AxiosResponse, AxiosRequestConfig } from "axios";
import { cloneDeep, isUndefined } from "lodash-es";
import { Env } from "./env";
import { MessageLoading, Server } from "./types";

export type ContentType =
    | "text/html"
    | "text/plain"
    | "multipart/form-data"
    | "application/json"
    | "application/x-www-form-urlencoded"
    | "application/octet-stream";

// api 响应
export interface Response<T = any> {
    status: boolean;
    message: string;
    code: number;
    time: string;
    trackId: string;
    data: T;
}

// api 响应处理函数
export type ResponseHandleFunc<T = any> = (
    data: T,
    apiResponse: Response<T>,
    response: AxiosResponse<Response<T>>,
) => void;

// api 请求完成处理函数
export type RequestCompleteHandleFunc<T = any> = (
    data?: T,
    apiResponse?: Response<T>,
    response?: AxiosResponse<Response<T>>,
) => void;

type Params = Record<string, any> | any[];

export class Request<T = any> {
    protected _method: Method = "get";
    protected _body: Params = {};
    protected _params: Params = {};
    protected _data?: Params;
    protected _header: Record<string, any> = {};
    protected _tipSuccess: boolean = false;
    protected _tipError: boolean = true;
    protected _loadingType: MessageLoading["type"] = "progress";
    protected _responseType: ResponseType = "json";
    protected _contentType: ContentType = "application/json";
    protected _start?: () => void;
    protected _fail?: () => void;
    protected _success?: ResponseHandleFunc<T>;
    protected _error?: ResponseHandleFunc<T>;
    protected _complete?: RequestCompleteHandleFunc<T>;

    // 其他请求配置
    protected _config: AxiosRequestConfig = {};

    constructor(
        protected readonly _env: Env,
        protected readonly _action: string,
    ) {}

    responseType(value: ResponseType) {
        this._responseType = value;
        return this;
    }

    loadingType(value: MessageLoading["type"]) {
        this._loadingType = value;
        return this;
    }

    contentType(value: ContentType) {
        this._contentType = value;
        return this;
    }

    config(value: AxiosRequestConfig) {
        this._config = cloneDeep(value);
        return this;
    }

    protected getConfig(): AxiosRequestConfig {
        return this._config;
    }

    contentTypeUrlencoded() {
        return this.contentType("application/x-www-form-urlencoded");
    }

    contentTypeMultipart() {
        return this.contentType("multipart/form-data");
    }

    // 自适应参数
    data(value: Params) {
        this._data = cloneDeep(value);
        return this;
    }

    // body 参数
    body(value: Params) {
        this._body = cloneDeep(value);
        return this;
    }

    // url 参数
    params(value: Params) {
        this._params = cloneDeep(value);
        return this;
    }

    header(value: Record<string, any>) {
        this._header = cloneDeep(value);
        return this;
    }

    showSuccessTip() {
        this._tipSuccess = true;
        return this;
    }

    hideErrorTip() {
        this._tipError = false;
        return this;
    }

    start(value: () => void) {
        this._start = value;
    }

    fail(value: () => void) {
        this._fail = value;
    }

    success(value: ResponseHandleFunc<T>) {
        this._success = value;
        return this;
    }

    error(value: ResponseHandleFunc<T>) {
        this._error = value;
        return this;
    }

    complete(value: RequestCompleteHandleFunc<T>) {
        this._complete = value;
        return this;
    }

    get() {
        this.execute("get");
    }

    post() {
        this.execute("post");
    }

    put() {
        this.execute("put");
    }

    delete() {
        this.execute("delete");
    }

    protected getStartHandleFunc() {
        return this._start;
    }

    protected getFailHandleFunc() {
        return this._fail;
    }

    protected getSuccessHandleFunc() {
        return this._success;
    }

    protected getErrorHandleFunc() {
        return this._error;
    }

    protected getCompleteHandleFunc() {
        return this._complete;
    }

    // 自适应参数设置
    private dataHandle() {
        if (isUndefined(this._data)) {
            return;
        }
        if (this._method.toLowerCase() === "get") {
            this._params = this._data;
            return;
        }
        this._body = this._data;
    }

    private execute(method: Method) {
        this._method = method;

        this.dataHandle();

        this.getStartHandleFunc()?.();
        Request.http<Response<T>>(
            this._env,
            this._action,
            this._method,
            this._params,
            this._body,
            this._responseType,
            this._contentType,
            this._header,
            this.getConfig(),
        )
            .then(response => {
                const apiResponse = response.data;
                this.getCompleteHandleFunc()?.(apiResponse.data, apiResponse, response);
                if (apiResponse.status) {
                    this.getSuccessHandleFunc()?.(apiResponse.data, apiResponse, response);
                    return;
                }
                this.getErrorHandleFunc()?.(apiResponse.data, apiResponse, response);
            })
            .catch(e => {
                this.getCompleteHandleFunc()?.();
                this.getFailHandleFunc()?.();
                console.error(e);
            });
    }

    static async http<T = any>(
        env: Env,
        action: string,
        method: Method,
        params: Params = {},
        body: Params = {},
        responseType: ResponseType = "json",
        contentType: ContentType = "application/json",
        _headers: Record<string, any> = {},
        _config: AxiosRequestConfig = {},
    ) {
        const headers: Record<string, any> = {
            ..._headers,
            ...env.header(),
            "content-type": contentType,
        };
        const config: AxiosRequestConfig = {
            ..._config,
            method,
            url: Env.createUrl(env.server.url, action),
            headers,
            responseType,
            params,
        };

        if (method.toLowerCase() !== "get") {
            config.data = body;
        }

        return axios<T>(config);
    }

    // 获取系统配置
    static syncConfig(url: string) {
        return axios<Response<Server>>({
            method: "get",
            url,
            responseType: "json",
        });
    }
}
