import { onMessage, sendMessage } from "promise-postmessage";

export type Type =
    // 框架页面刷新
    | "FeatureRefresh"
    // 框架页面标题
    | "FeatureTitle"
    // 获取上下文信息
    | "DashboardContext"
    // 打开页面
    | "DashboardOpen"
    // 关闭页面
    | "DashboardClose"
    // 消息提示
    | "DashboardNotice"
    // 页面加载动画
    | "DashboardLoading";

type Content = {
    type: Type;
    data: any;
};

type Listener = (data: any) => any;

type MessageElement = HTMLElement | Window;

export class Message {
    private readonly listeners: Map<Type, Listener> = new Map();

    constructor(private readonly element: MessageElement) {
        this.element = element;
        // 消息监听器
        onMessage((content: Content) => {
            let listener = this.listeners.get(content.type);
            if (!listener) {
                console.warn(`没有找到${content.type}的监听器`, content);
                return;
            }
            return listener(content.data);
        }, element);
    }

    public addListener(type: Type, listener: Listener) {
        this.listeners.set(type, listener);
    }

    public async send<T = any>(type: Type, data?: any) {
        return (await sendMessage(this.element, { type, data }).catch(e => console.error(e))) as T;
    }
}

export const createMessage = (element: MessageElement) => {
    return new Message(element);
};
