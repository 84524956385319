import { MessageNotice, MessageLoading, MessagePage } from "../types";
import { EnvSerializeType } from "@/env.ts";
import { Load } from "@/load.ts";

export abstract class MessageListenerAbstract {
    abstract notice(): (data: MessageNotice) => void;

    abstract loading(): (data: MessageLoading) => void;

    abstract open(): (data: MessagePage) => void;

    abstract close(): () => void;

    abstract context(): () => { env: EnvSerializeType; context: Load };
}
